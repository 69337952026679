@media only screen and (min-width: 1440px) and (max-width: 1999px) {
  .login-menu {
    width: 400px;
    height: 130px;
    border-radius: 10px;
    font-size: 30px;
  }
  .login_form_container {
    width: 400px;
  }
  .logo_image {
    height: 70px;
  }
  .header_text {
    font-size: 1.5rem;
  }
  .header_subtext {
    font-size: 1.1rem;
  }
  .vip-booking-history-card {
    display: none !important;
  }
  .logo_image_drawer {
    height: 50px !important;
    width: 50px !important;
  }

  .header_text_drawer {
    font-size: 13px;
  }
  .drawer-close-icon {
    width: 40px;
    height: 40px;
  }
  .vip-history-card {
    display: none !important;
  }
  .vip-booking-history-no-data-container {
    display: none !important;
  }
  .logout_logo {
    display: none !important;
  }
  .thank_you_message {
    font-size: 27px;
  }
  .login_button {
    width: 100% !important;
  }
  .placeholder-black {
    width: 1005 !important;
  }
}
