.loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #ef49b8;
  --third-color: #f6bb02;
  --fourth-color: #0b8444;
  --fifth-color: #991cd7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  gap: 20px;
  height: 100px;
}

.loading span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}
/* ...................... */

/* General container styles */
.webcam-container {
  text-align: center;
  padding: 10px;
}

/* Modal styles */
.webcam-modal .ant-modal-content {
  max-width: 100%;
}

.webcam-modal-content {
  text-align: center;
}

/* Webcam styles */
.webcam-section {
  margin: auto;
  max-width: 100%;
  width: 100%;
}

.webcam-video {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.capture-photo-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.captured-image {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.action-buttons {
  margin-top: 10px;
}

.popup-confirmation .ant-popover-message {
  display: flex !important;
}
/* Retake button */
.retake-photo-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.thank_you_modal_content_main_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thank_you_image {
  height: 80%;
  width: 80%;
}
.thank_you_modal_content {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}
/* Responsiveness */
/* loading css */
/* From Uiverse.io by Vazafirst */
#page {
  display: flex;
  justify-content: center;
  align-items: center;
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#h3 {
  color: rgb(82, 79, 79);
}

#ring {
  width: 190px;
  height: 190px;
  border: 1px solid transparent;
  border-radius: 50%;
  position: absolute;
}

#ring:nth-child(1) {
  border-bottom: 8px solid rgb(240, 42, 230);
  animation: rotate1 2s linear infinite;
}

.custom-modal .ant-modal-content {
  border-radius: 12px;
  background-color: #f9f9f9;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.custom-modal .ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.custom-modal .ant-modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
/* .print-design {
  background-image: url("C:\Users\admin\Downloads\vip_receipt_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 99999;
}

.print-design-normal {
  background-image: url("C:\Users\admin\Downloads\vip_receipt_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 99999;
} */
.login_segment .ant-segmented-item-label {
  padding: 0 20px !important;
}

.login_segment .ant-segmented-item-selected {
  background-color: #ffff;
  border-radius: 2px;
  box-shadow: red;
  /* box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05), 0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08); */

  color: black;
}

.login_segment .ant-segmented {
  background-color: red !important;
}

@keyframes rotate1 {
  from {
    transform: rotateX(50deg) rotateZ(110deg);
  }

  to {
    transform: rotateX(50deg) rotateZ(470deg);
  }
}

#ring:nth-child(2) {
  border-bottom: 8px solid rgb(240, 19, 67);
  animation: rotate2 2s linear infinite;
}

@keyframes rotate2 {
  from {
    transform: rotateX(20deg) rotateY(50deg) rotateZ(20deg);
  }

  to {
    transform: rotateX(20deg) rotateY(50deg) rotateZ(380deg);
  }
}

#ring:nth-child(3) {
  border-bottom: 8px solid rgb(3, 170, 170);
  animation: rotate3 2s linear infinite;
}

@keyframes rotate3 {
  from {
    transform: rotateX(40deg) rotateY(130deg) rotateZ(450deg);
  }

  to {
    transform: rotateX(40deg) rotateY(130deg) rotateZ(90deg);
  }
}

#ring:nth-child(4) {
  border-bottom: 8px solid rgb(207, 135, 1);
  animation: rotate4 2s linear infinite;
}

@keyframes rotate4 {
  from {
    transform: rotateX(70deg) rotateZ(270deg);
  }

  to {
    transform: rotateX(70deg) rotateZ(630deg);
  }
}
/* Improving visualization in light mode */

/* ...................... */

@keyframes scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}
