@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .login_form_container {
    width: 45% !important;
  }
  .placeholder-black {
    width: 100% !important;

  }
  .login_button {
    width: 100% !important;

  }
  .logo_image {
    height: 50px;
  }
  .logo_image_drawer {
    height: 40px !important;
    width: 40px !important;
  }
  .header_text {
    font-size: 1.1rem;
  }
  .header_subtext {
    font-size: 0.9rem;
  }
  .vip-booking-table {
    display: none !important;
  }
  .vip-drawer-menus-title-text {
    font-size: 20px;
  }
  .vip-booking-history-card {
    margin: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 10px;
  }

  .vip-booking-history-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .vip-booking-history-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
  }

  .vip-booking-history-item-half {
    width: 49% !important;
  }

  .vip-booking-history-title {
    font-weight: bold;
    color: #333;
    font-size: 15px;
  }

  .vip-booking-history-text {
    color: #555;
    font-size: 13px;
  }
  .vip-history-table {
    display: none !important;
  }
  .vip-history-card {
    padding: 10px !important;
  }
  .vip-booking-side-bar {
    display: none !important;
  }
  .drawer-close-icon {
    width: 30px;
    height: 30px;
  }
  .thank_you_message
{
  font-size: 25px;
}
.login_button {
  width: 100% !important;
}
}
