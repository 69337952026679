@media only screen and (min-width: 0px) and (max-width: 767px) {
  .login_form_container {
    width: 98% !important;
  }
  .placeholder-black {
    width: 300px !important;
  }
  .login_button {
    width: 100% !important;
  }
  .login-menu {
    height: 70px;
    color: #fff;
    font-size: 15px;
  }
  .vip_booking_time_slots {
    font-size: 10px;
    width: 120px;
  }
  .vip_booking_user_form_card {
    width: 100%;
    background-color: #ffde8b;
  }
  .vip_booking_user_form_card .ant-card-body {
    padding: 0px;
  }
  .leader_details_card {
    width: 100%;
    padding: 10px;
  }
  .vip_booking_form_item_single_container {
    display: flex;
    flex-direction: column !important;
  }
  .vip_booking_form_item_single_wrapper {
    width: 49%;
  }
  .vip_booking_form_item_container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .vip_booking_form_item_wrapper {
    width: 100%;
  }
  .vip_booking_amt_container {
    margin-bottom: "20px" !important;
  }
  .login_header {
    height: 18% !important;
  }
  .logo_image {
    height: 40px;
  }

  .logo_image_drawer {
    height: 40px !important;
    width: 40px !important;
  }
  .header_text {
    font-size: 13px;
  }
  .header_subtext {
    font-size: 11px;
  }
  .header_container {
    width: 100% !important;
  }
  .logout_logo {
    height: 30px;
    width: 30px;
  }

  .side_bar {
    height: 30px;
    width: 30px;
  }
  .vip-booking-table {
    display: none !important;
  }

  .vip-booking-history-card {
    margin: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .vip-booking-history-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  .vip-booking-history-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
  }

  .vip-booking-history-item-half {
    width: 49% !important;
  }

  .vip-booking-history-title {
    font-weight: bold;
    color: #333;
    font-size: 15px;
  }

  .vip-booking-history-text {
    color: #555;
    font-size: 13px;
  }
  .header_text_drawer {
    font-size: 13px;
  }
  .drawer-close-icon {
    width: 30px;
    height: 30px;
  }
  .vip-drawer-menus-title-text {
    font-size: 20px;
  }
  .vip-history-table {
    display: none !important;
  }
  .vip-history-card {
    display: flex;
    justify-content: center;
  }
  .vip-booking-side-bar {
    display: none !important;
  }

  .webcam-modal {
    width: 100%;
  }

  .webcam-container {
    padding: 10px;
  }

  .take-photo-button {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 5px !important;
    border: none !important;
    border-radius: 10px;
    background-color: pink;
    color: #ffffff;
  }

  .capture-photo-btn,
  .retake-photo-btn {
    width: 100%;
    margin-top: 10px;
  }

  .captured-image-display {
    margin-top: 20px;
    width: 100%;
  }

  .captured-image {
    width: 100%;
  }
  .thank_you_message {
    font-size: 20px;
  }
}
