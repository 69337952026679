@media Print {
    .print-content {
      border: 2px solid #000; 
      padding: 10px; 
    }
    .custom-row  {
      height: 40px; 
      color:"green"
    }
    /* .print-design {
      background-image: url("C:\Users\admin\Downloads\vip_receipt_image.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    
    .print-design-normal {
      background-image: url("C:\Users\admin\Downloads\vip_receipt_image.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    } */
  }
  @media print {
    .custom-row-denomination {
      border: 1px solid #000; 
    }
  }
  