@media only screen and (min-width: 2000px) and (max-width: 2559px) {
  .logo_image {
    height: 80px;
  }
  .header_text {
    font-size: 1.75rem;
  }
  .header_subtext {
    font-size: 1.2rem;
  }
  .vip-booking-history-card {
    display: none !important;
  }
  .vip-history-card {
    display: none !important;
  }
  .vip-booking-history-no-data-container {
    display: none !important;
  }
  .logout_logo {
    display: none !important;
  }
  .thank_you_message {
    font-size: 29px;
  }
  .login_button {
    width: 100% !important;
  }
  .placeholder-black {
    width: 1005 !important;
  }
}
