.login_form_container {
  background-color: #ffde8b !important;
  border-radius: 8px;
}

.placeholder-black {
  width: 300px;
}
.login_button {
  width: 300px;
}
.back_button {
  width: 300px !important;
}
.login-menu {
  width: 300px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.login-menu-container {
  width: 300px;
}
.leader_details_title {
  font-weight: bold;
}
.leader_details_card {
  margin: 5px auto;
  min-height: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}
.vip_booking_user_form_card {
  width: 98%;
  margin: 5px auto;
  min-height: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  max-width: 800px;
  background-color: #ffde8b;
}
.vip_booking_time_slots {
  font-size: 14px;
  /* margin-right: 5px;
    margin-bottom: 5px; */
  border-radius: 8px;
  width: 160px;
}
.vip_booking_form_item_wrapper {
  width: 49%;
}
.vip_booking_form_item_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.vip_booking_form_item_single_wrapper {
  width: 49%;
}
.vip_booking_form_item_double_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.vip_booking_form_item_double_wrapper {
  width: 49%;
}
.vip_booking_amt_container {
  margin-bottom: "2%" !important;
}

.login_header {
  padding: 10px;
  background: linear-gradient(to right, #facf61, #ec9501);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
}

.logo_image {
  height: 60px !important;
  width: 60px !important;
}

.logo_image_drawer {
  height: 20px !important;
  width: 20px !important;
}
/* Default font and alignment styles */
.header_text {
  font-size: 1.25rem;
  text-align: center;
  color: #fe321f !important;
  font-weight: bold;
}
.header_text_drawer {
  /* font-size: 1.25rem; */
  text-align: center;
  color: #f7fff0 !important;
  font-weight: bold;
}
.header_subtext {
  font-size: 1rem;
  text-align: center;
  color: #313131;
}
.logout_logo {
  height: 50px;
  width: 50px;
}
.side_bar {
  height: 50px;
  width: 50px;
}

.vip_booking_user_button {
  background-color: #033e3e !important;
  color: #fff !important;
}
.vip_booking_user_button:hover {
  background-color: #033e3e !important;
  color: #fff !important;
}
.vip_booking_user_disabled_button {
  background-color: gray;
  color: black;
  opacity: 0.5;
}
.vip_booking_history_title {
  font-weight: 500;
}
.vip-booking-history-card .ant-card-body {
  padding: 8px !important;
}
.vip-booking-history-no-data .ant-card-body {
  padding: 8px !important;
}

.vip-booking-history-no-data {
  margin: 10px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  width: 90%;
}

.vip-booking-history-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  background-color: transparent;
  border: none;
}

.otp-input .ant-otp-input {
  color: rgb(33, 32, 32) !important;
  background-color: #ffde8b !important;
}
.mobile-drawer-vip .ant-drawer-header {
  padding: 0px !important;
}

.drawer-close-icon {
  color: #fff;
  cursor: "pointer";
}
.vip-drawer-menus-title-text {
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  padding-left: 20px;
  font-weight: bold;
}
.vip-history-table {
  padding: 20px !important;
}
.vip-booking-card {
  padding: 20px !important;
}
.vip-history-header {
  padding: 10px;
}
.side_bar {
  cursor: pointer;
}
.vip-booking-side-bar .ant-layout-sider-trigger {
  background-color: transparent !important;
}
.take-photo-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 5px !important;
  border: none !important;
  border-radius: 10px;
  background-color: rgb(8, 83, 144) !important;
  color: #ffffff !important;
}
/* ........... */
/* Modal and Image */
.responsive-modal {
  max-width: 100%;
}

.responsive-image {
  width: 100%;
  max-width: 600px; /* Optional: Set a max width */
  height: auto;
  margin-bottom: 1rem;
}

/* Webcam */
.responsive-webcam {
  width: 100%;
  max-width: 600px;
  margin-bottom: 1rem;
}

/* Buttons in the modal */
.webcam-capture-container .ant-btn {
  width: 100%;
  max-width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
}
