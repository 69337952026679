@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .back_button {
    width: 300px !important;
  }
  .otp-input .ant-otp-input {
    height: 30px !important;
  }
  .login_form_container {
    width: 350px;
  }
  .logo_image {
    height: 120px !important;
    width: 120px !important;
  }
  .header_text {
    font-size: 1.25rem;
  }

  .logo_image_drawer {
    height: 50px !important;
    width: 50px !important;
  }

  .header_text_drawer {
    font-size: 12px;
  }
  .header_subtext {
    font-size: 1rem;
  }
  .vip-booking-history-card {
    display: none !important;
  }
  .drawer-close-icon {
    width: 30px;
    height: 30px;
  }
  .vip-drawer-menus-title-text {
    font-size: 15px;
  }
  .vip-history-card {
    display: none !important;
  }
  .vip-booking-history-no-data-container {
    display: none !important;
  }
  .logout_logo {
    display: none !important;
  }
  .thank_you_message {
    font-size: 25px;
  }
  .login_button {
    width: 100% !important;
  }
  .placeholder-black {
    width: 1005 !important;
  }
}
